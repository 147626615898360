<template>
  <div>
    <div class="terms mt-2">
      <div class="text-center pb-1">
        <h4 class="text-black title">
          {{ $t('src.components.privacypolicy.privacyTP') }}
        </h4>
      </div>
      <div>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.gologT') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.gologPI') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.theSA') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.forTA') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.weDN') }}
        </p>
      </div>
      <div class="">
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.generalC') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.gologTA') }}</li>
          <li>{{ $t('src.components.privacypolicy.byVO') }}</li>
          <li>{{ $t('src.components.privacypolicy.gologDN') }}</li>
          <li>{{ $t('src.components.privacypolicy.theseTA') }}</li>
        </ol>
      </div>
      <div class="pb-1">
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.purposeOS') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.gologIA') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.gologDN') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.userE') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.usersOG') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.contractBG') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.byUG') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.paymentFJ') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.acceptableUPS') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.usersOG') }}
        </p>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.violatesAS') }}</li>
          <li>{{ $t('src.components.privacypolicy.violatesAR') }}</li>
          <li>{{ $t('src.components.privacypolicy.violatesAR') }}</li>
          <li>{{ $t('src.components.privacypolicy.violatesAR') }}</li>
          <li>{{ $t('src.components.privacypolicy.isFD') }}</li>
          <li>{{ $t('src.components.privacypolicy.causesOT') }}</li>
          <li>{{ $t('src.components.privacypolicy.resultsIO') }}</li>
          <li>{{ $t('src.components.privacypolicy.involvesAO') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.terminationAS') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.privacypolicy.gologRT') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.eligibilityAR') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.ourSA') }}</li>
          <li>{{ $t('src.components.privacypolicy.yourII') }}</li>
          <li>{{ $t('src.components.privacypolicy.bySU') }}</li>
          <li>{{ $t('src.components.privacypolicy.youAS') }}</li>
          <li>{{ $t('src.components.privacypolicy.youAA') }}</li>
          <li>{{ $t('src.components.privacypolicy.gologRT') }}</li>
          <li>{{ $t('src.components.privacypolicy.gologTI') }}</li>
          <li>{{ $t('src.components.privacypolicy.byUT') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.theUH') }}</li>
            <li>{{ $t('src.components.privacypolicy.theUW') }}</li>
            <li>{{ $t('src.components.privacypolicy.theUI') }}</li>
            <li>{{ $t('src.components.privacypolicy.theUS') }}</li>
            <li>{{ $t('src.components.privacypolicy.theUM') }}</li>
          </ol>
          </li>
          <li>{{ $t('src.components.privacypolicy.byUT') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.theGM') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGP') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGO') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGV') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGW') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGW') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGW') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGI') }}</li>
            <li>{{ $t('src.components.privacypolicy.theGS') }}</li>
          </ol>
          </li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.independentC') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.allGA') }}</li>
          <li>{{ $t('src.components.privacypolicy.gologersRT') }}</li>
          <li>{{ $t('src.components.privacypolicy.byBA') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.gologerTAF') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.asAU') }}</li>
          <li>{{ $t('src.components.privacypolicy.aMF') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.aUD') }}</li>
            <li>{{ $t('src.components.privacypolicy.ourDT') }}</li>
            <li>{{ $t('src.components.privacypolicy.disruptionCT') }}</li>
            <li>{{ $t('src.components.privacypolicy.anyORW') }}</li>
          </ol>
          </li>
          <li>{{ $t('src.components.privacypolicy.glogccomAI') }}</li>
          <li>{{ $t('src.components.privacypolicy.inTE') }}</li>
          <li>{{ $t('src.components.privacypolicy.gologerIN') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.restrictedA') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.youAT') }}</li>
          <li>{{ $t('src.components.privacypolicy.withRT') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.impersonateAP') }}</li>
            <li>{{ $t('src.components.privacypolicy.stalkOO') }}</li>
            <li>{{ $t('src.components.privacypolicy.expressOI') }}</li>
            <li>{{ $t('src.components.privacypolicy.useAR') }}</li>
            <li>{{ $t('src.components.privacypolicy.publishDO') }}</li>
            <li>{{ $t('src.components.privacypolicy.removeAC') }}</li>
            <li>{{ $t('src.components.privacypolicy.interfereWO') }}</li>
            <li>{{ $t('src.components.privacypolicy.postEO') }}</li>
            <li>{{ $t('src.components.privacypolicy.forgeHO') }}</li>
            <li>{{ $t('src.components.privacypolicy.frameOM') }}</li>
            <li>{{ $t('src.components.privacypolicy.modifyAS') }}</li>
          </ol>
          </li>
          <li>{{ $t('src.components.privacypolicy.yourFA') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.beFI') }}</li>
            <li>{{ $t('src.components.privacypolicy.infringeAT') }}</li>
            <li>{{ $t('src.components.privacypolicy.violateAL') }}</li>
            <li>{{ $t('src.components.privacypolicy.beAE') }}</li>
            <li>{{ $t('src.components.privacypolicy.containAO') }}</li>
            <li>{{ $t('src.components.privacypolicy.containAV') }}</li>
            <li>{{ $t('src.components.privacypolicy.createLF') }}</li>
            <li>{{ $t('src.components.privacypolicy.linkDO') }}</li>
            <li>{{ $t('src.components.privacypolicy.allowAT') }}</li>
            <li>{{ $t('src.components.privacypolicy.causeOA') }}</li>
          </ol>{{ $t('src.components.privacypolicy.weRT') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.billingAP') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.partnersCC') }}</li>
          <li>{{ $t('src.components.privacypolicy.allOI') }}</li>
          <li>{{ $t('src.components.privacypolicy.ifAA') }}</li>
          <li>{{ $t('src.components.privacypolicy.glogccomA') }}</li>
        </ol>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.usersOT') }}
        </p>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.byUG') }}
        </p>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.byAT') }}
        </p>
        <ol class="list bold">
          <li>{{ $t('src.components.privacypolicy.paymentT') }}<ol class="custom-list normal">
            <li>{{ $t('src.components.privacypolicy.thisCA') }}</li>
          </ol>
          </li>
        </ol>
        <ol
          class="list bold"
          start="2"
        >
          <li>{{ $t('src.components.privacypolicy.changesOT') }}<ol
            class="custom-list normal"
            start="2"
          >
            <li>{{ $t('src.components.privacypolicy.thisCA') }}</li>
          </ol>
          </li>
        </ol>
        <ol
          class="list bold"
          start="3"
        >
          <li>{{ $t('src.components.privacypolicy.minimumR') }}<ol
            class="custom-list normal"
            start="3"
          >
            <li>{{ $t('src.components.privacypolicy.toOO') }}</li>
          </ol>
          </li>
        </ol>
        <ol
          class="list bold"
          start="4"
        >
          <li>{{ $t('src.components.privacypolicy.gologCT') }}<ul
            class="bold"
            style="list-style-type:none;"
          >
            <li>{{ $t('src.components.privacypolicy.payment') }}<ol class="list normal reset-list-numbering">
              <li>{{ $t('src.components.privacypolicy.byAA') }}</li>
              <li>{{ $t('src.components.privacypolicy.whenAG') }}</li>
              <li>{{ $t('src.components.privacypolicy.youAT') }}</li>
              <li>{{ $t('src.components.privacypolicy.ifYA') }}</li>
              <li>{{ $t('src.components.privacypolicy.accountBC') }}</li>
              <li>{{ $t('src.components.privacypolicy.glogccomHF') }}</li>
              <li>{{ $t('src.components.privacypolicy.asAG') }}</li>
              <li>{{ $t('src.components.privacypolicy.inTE') }}</li>
            </ol>
            </li>
            <li>{{ $t('src.components.privacypolicy.incentives') }}<br>
              <span class="normal p-size">{{ $t('src.components.privacypolicy.fromTT') }}</span></li>
            <li>{{ $t('src.components.privacypolicy.payment') }}<ol class="list normal reset-list-numbering">
              <li>{{ $t('src.components.privacypolicy.glogccomHT') }}</li>
              <li>{{ $t('src.components.privacypolicy.youAT') }}</li>
              <li>{{ $t('src.components.privacypolicy.aRW') }}</li>
            </ol>
            </li>
          </ul>
          </li>
        </ol>
        <ol
          class="list bold"
          start="5"
        >
          <li>{{ $t('src.components.privacypolicy.priceA') }}<ol
            class="custom-list normal"
            start="5"
          >
            <li>{{ $t('src.components.privacypolicy.weMI') }}</li>
          </ol>
          </li>
        </ol>
        <ol
          class="list bold"
          start="6"
        >
          <li>{{ $t('src.components.privacypolicy.accessingOS') }}<ul
            class="normal"
            style="list-style-type:none;"
          >
            <li>{{ $t('src.components.privacypolicy.weM') }}</li>
            <li>{{ $t('src.components.privacypolicy.weM') }}</li>
          </ul>
          </li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.insurance') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.allGA') }}</li>
          <li>{{ $t('src.components.privacypolicy.glogccomWD') }}</li>
          <li>{{ $t('src.components.privacypolicy.toPT') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.socialMA') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.asPO') }}</li>
          <li>{{ $t('src.components.privacypolicy.youRT') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.gologEA') }}
        </h5>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.emailCA') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.informationC') }}
        </h5>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.locationDP') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.proprietaryR') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.gobuildersNS') }}</li>
          <li>{{ $t('src.components.privacypolicy.youWA') }}</li>
          <li>{{ $t('src.components.privacypolicy.gobuildersNS') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.aDO') }}</li>
            <li>{{ $t('src.components.privacypolicy.aDO') }}</li>
            <li>{{ $t('src.components.privacypolicy.yourAT') }}</li>
            <li>{{ $t('src.components.privacypolicy.aSB') }}</li>
            <li>{{ $t('src.components.privacypolicy.aSB') }}</li>
            <li>{{ $t('src.components.privacypolicy.anEO') }}</li>
          </ol>
          </li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.noticesC') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.exceptAE') }}<br><br>{{ $t('src.components.privacypolicy.gobuildersNSB') }}<br>{{ $t('src.components.privacypolicy.noJI') }}<br>{{ $t('src.components.privacypolicy.tamanM') }}<br>{{ $t('src.components.privacypolicy.puchong') }}<br>{{ $t('src.components.privacypolicy.selangorDE') }}<br><br>
          </li>
          <li>{{ $t('src.components.privacypolicy.additionallyAN') }}</li>
          <li>{{ $t('src.components.privacypolicy.toRA') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.breachI') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.withoutLO') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.youBT') }}</li>
            <li>{{ $t('src.components.privacypolicy.weAU') }}</li>
            <li>{{ $t('src.components.privacypolicy.weBT') }}</li>
            <li>{{ $t('src.components.privacypolicy.ifWS') }}</li>
          </ol>
          </li>
          <li>{{ $t('src.components.privacypolicy.youWD') }}<ol
            class="list"
            type="a"
          >
            <li>{{ $t('src.components.privacypolicy.yourBO') }}</li>
            <li>{{ $t('src.components.privacypolicy.yourVO') }}</li>
            <li>{{ $t('src.components.privacypolicy.anyAT') }}</li>
            <li>{{ $t('src.components.privacypolicy.yourOU') }}</li>
            <li>{{ $t('src.components.privacypolicy.anyOA') }}</li>
          </ol>
          </li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.onlineCD') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.opinionsAS') }}</li>
          <li>{{ $t('src.components.privacypolicy.theGP') }}</li>
          <li>{{ $t('src.components.privacypolicy.inTE') }}</li>
          <li>{{ $t('src.components.privacypolicy.theGP') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.limitationOL') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.privacypolicy.weAG') }}</li>
          <li>{{ $t('src.components.privacypolicy.inNE') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.confidentiality') }}
        </h5>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.youAN') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.privacypolicy.termination') }}
        </h5>
        <p class="p-size text-black">
          {{ $t('src.components.privacypolicy.eitherYO') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
  .text-black {
    font-family: Arial, Helvetica, sans-serif;
  }
  .p-size {
    font-size: 14px !important;
  }
  .title {
    font-weight: bold;
  }
  .list li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
  }
  .bold {
    font-weight: bold;
  }
  .normal {
    font-weight: normal;
  }
  .custom-list { counter-reset: item }
  .custom-list>li:before { content: counters(item, ".") " "; counter-increment: item }

</style>
